<!-- <div id="overlay-container"
     (window:keydown.esc)="close()"
     class="overlay_screenshots">

  <a href="javascript:void(0)"
     class="closebtn"
     (click)="close()">&times;</a>

  <div class="overlay_screenshots-content  w-100">

    <div class="screenshot_image">
      <img [src]="path">
    </div>
  </div> -->
<div id="image-viewer-popup">
  <!-- Your popup content -->

  <div class="d-flex justify-content-between align-items-center">
    <!-- <div class="left-image-control">                -->

    <!-- <button [class.disabled]="imageIndex <= 0" (click)="previousImage()"><i class="fa fa-chevron-left"></i>prev</button>
        <button [class.disabled]="imageIndex >= images.length-1" (click)="nextImage()"><i class="fa fa-chevron-right"></i>next</button>            -->
    <!-- <button><i class="fa fa-file-pdf-o"></i>PDF</button> -->
    <!-- <button (click)="openFullscreen()" ><i class="fa" [class.fa-expand]="!fullscreen" [class.fa-compress]="fullscreen"></i>open</button>
        <button (click)="closeFullscreen()" ><i class="fa" [class.fa-expand]="!fullscreen" [class.fa-compress]="fullscreen"></i>close</button> -->

    <!-- <button (click)="openFullscreen()" >Fullscreen</button>
        <button (click)="closeFullscreen()" >Close Fullscreen</button> -->
    <div>
      <span class="counter">{{imageIndex+1}} / {{images.length}}</span>
    </div>
    <div class="d-flex">
      <button (click)="downloadImage()" class="btn-control"><i class="fa fa-download" aria-hidden="true"></i></button>
      <button (click)="rotateRight()" class="btn-control"><i class="fa fa-repeat" aria-hidden="true"></i></button>
      <button (click)="rotateLeft()" class="btn-control"><i class="fa fa-undo" aria-hidden="true"></i></button>
      <button (click)="zoomIn()" class="btn-control"><i class="fa fa-search-plus" aria-hidden="true"></i></button>
      <button (click)="zoomOut()" class="btn-control"><i class="fa fa-search-minus" aria-hidden="true"></i></button>
      <button (click)="resetZoom()" class="btn-control"><i class="fa fa-arrows-alt" aria-hidden="true"></i></button>
      <button (click)="close()" class="btn-control"><i class="fa fa-times" aria-hidden="true"></i></button>
    </div>
    <!-- </div> -->
  </div>



  <div class="image-viewer-container" [class.fullscreen]="fullscreen">
    <div class="image-viewer">
      <ngx-image-viewer #imageViewer [src]="images" [(index)]="imageIndex"></ngx-image-viewer>
    </div>
  </div>
  <div class="d-flex justify-content-center">

    <div class="small-images">


      <button [class.disabled]="imageIndex <= 0" (click)="previousImage()" class="btn-control"><i
          class="fa fa-arrow-left" aria-hidden="true"></i></button>

      <div class="images-container" #imagesContainer>
        <ng-container *ngFor="let img of images; let i=index">
          <img src="{{img}}" [ngClass]="{'active': imageIndex === i}" (click)="showCurrentImage(i)">
        </ng-container>
      </div>

      <button [class.disabled]="imageIndex >= images.length-1" (click)="nextImage()" class="btn-control"><i
          class="fa fa-arrow-right" aria-hidden="true"></i></button>

    </div>
  </div>

</div>