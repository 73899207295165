import {
  Component, OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { RtlService } from '@core/services/rtl.service';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [Title]
})
export class AppComponent implements OnInit {
  loading: boolean;

  

  constructor(
    public authService:AuthService,
    rtlService: RtlService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
    ) {
    this.loading = true;

    this.authService.runInitialLoginSequence();


    rtlService.load().then(() => {

      var switchTenant = localStorage.getItem('switchTenant');
      var tenant = localStorage.getItem('businessName');
      if (tenant && switchTenant) {

        localStorage.removeItem('switchTenant');
        window.location.reload();
      }
      this.loading = false;
    });
  }
  async ngOnInit() {
  }

}
