import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageViewerComponent as NgxImageViewerComponent } from 'ngx-image-viewer';

@Component({
  selector: 'bk-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {

  
  // @ViewChild("imageViewer")

  // viewer: NgxImageViewerComponent; 

  @ViewChild("imageViewer") viewer: NgxImageViewerComponent;
  @ViewChild("imagesContainer") imagesContainer!: ElementRef;

  fullscreen: boolean = false;
  private debounceTimeout: any = null; // Timeout variable for debouncing
  // imageIndex: number = 0;

  images: string[] = [
  ]; 


  // @Input() path: string[];
  @Input() path: string[] = [];
  @Input() imageIndex: number = 0;
  constructor(
    public dialogRef: MatDialogRef<ImageViewerComponent>,
  
  ) {

  }

  ngOnInit(): void {
    if (Array.isArray(this.path) && this.path.every(item => typeof item === 'string')) {
      this.images = this.path;
  } else {
      this.images = [this.path.toString()];
  }
  // this.images = this.path;
  }

  ngAfterViewInit(): void {
    this.scrollToActiveImage(); // Added to scroll to active image after view initialization
  }


  // Method to scroll the container to the active image
  private scrollToActiveImage(): void {
    console.log('kuch b');
    
    const container = this.imagesContainer.nativeElement;
    const activeImage = container.querySelectorAll('img')[this.imageIndex];
    if (activeImage) {
      const containerRect = container.getBoundingClientRect();
      const imageRect = activeImage.getBoundingClientRect();

      // Check if the active image is out of view on the left
      if (imageRect.left < containerRect.left) {
        container.scrollLeft -= (containerRect.left - imageRect.left);
      }

      // Check if the active image is out of view on the right
      if (imageRect.right > containerRect.right) {
        container.scrollLeft += (imageRect.right - containerRect.right);
      }
    }
  }


  zoomIn() {
    this.viewer.zoomIn();
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
  }

  zoomOut() {
    this.viewer.zoomOut();
  }

  rotateLeft() {
    this.viewer.rotateCounterClockwise();
  }
  
  rotateRight() {
    this.viewer.rotateClockwise();
  }

  nextImage() {
    this.imageIndex = Math.min(this.imageIndex + 1, this.images.length - 1); 
    this.scrollToActiveImage();
  }

  previousImage() {
    this.imageIndex = Math.max(this.imageIndex - 1, 0); 
    this.scrollToActiveImage();
  }

  resetZoom() {
    this.viewer.reset();
  }

  // openFullscreen(): void {
  //   const elem = document.documentElement as HTMLElement & {
  //     webkitRequestFullscreen?: () => Promise<void>;
  //     msRequestFullscreen?: () => Promise<void>;
  //   };
  
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) { // For Safari
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) { // For IE11
  //     elem.msRequestFullscreen();
  //   }
  // }

  // closeFullscreen(): void {
  //   const doc = document as Document & {
  //     webkitExitFullscreen?: () => Promise<void>;
  //     msExitFullscreen?: () => Promise<void>;
  //   };
  
  //   if (doc.exitFullscreen) {
  //     doc.exitFullscreen();
  //   } else if (doc.webkitExitFullscreen) { // For Safari
  //     doc.webkitExitFullscreen();
  //   } else if (doc.msExitFullscreen) { // For IE11
  //     doc.msExitFullscreen();
  //   }
  // }
  

  downloadImage() {
    const imageUrl = this.images[this.imageIndex];
    this.downloadMyFile(imageUrl);
  }
  
  downloadMyFile(myLink: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', myLink);
    link.setAttribute('download', 'image.png'); // Set default name for downloaded file
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  close() {
    this.dialogRef.close();
  }

  showCurrentImage(index:number){
    this.imageIndex = index;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent): void {
    event.stopPropagation();
    this.close();
  }
  

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.scrollToActiveImage();
    // Prevent repeated triggers by ignoring further keydown events within a short interval
    // if (this.debounceTimeout) {
    //   clearTimeout(this.debounceTimeout);
    // }
    // this.debounceTimeout = setTimeout(() => {
    //   if (event.key === 'ArrowLeft') {
    //     this.previousImage();
    //   } else if (event.key === 'ArrowRight') {
    //     this.nextImage();
    //   }
    // }, 300); // 100 ms debounce interval to allow one action per key press
  }

}
