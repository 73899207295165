<style>
    :host {
        height: 100%;
    }
</style>
<ng-container *ngIf="!loading">
    <!-- <ngx-loading [show]="authService.getLoader()"></ngx-loading> -->
    <router-outlet></router-outlet>
</ng-container>


