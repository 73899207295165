import { RouterModule } from '@angular/router';
import { NgModule, OnInit } from '@angular/core';
import {
  NgbDropdownModule,
  NgbProgressbarModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslationModule } from '@themes/metronic/app/modules/i18n/translation.module';
import { ExtrasModule } from '@themes/metronic/app/_metronic/partials/layout/extras/extras.module';
import { SubheaderModule } from '@themes/metronic/app/_metronic/partials/layout/subheader/subheader.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { Error1Component } from '@themes/metronic/app/modules/errors/error1/error1.component';

import {
  CoreModule,
  DynamicAsideMenuService,
  LayoutService,
} from '@themes/metronic/app/_metronic/core';
// language list

import { AsideSearchComponent } from './aside/aside-search/aside-search.component';
import { AsideStaticComponent } from './aside/aside-static/aside-static.component';
import { AsideComponent } from './aside/aside.component';
import { AsideDynamicComponent } from './aside/aside-dynamic/aside-dynamic.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { TopbarComponent } from './topbar/topbar.component';
import { LanguageSelectorComponent } from './topbar/language-selector/language-selector.component';
import { TranslationService } from '@themes/metronic/app/modules/i18n/translation.service';
import { HeaderMenuDynamicComponent } from './header/header-menu-dynamic/header-menu-dynamic.component';
import { DropdownMenusModule } from '@themes/metronic/app/_metronic/partials/content/dropdown-menus/dropdown-menus.module';
import { ScriptsInitComponent } from './scipts-init/scripts-init.component';
import { CommonModule } from '@angular/common';
import { AuthService } from '@core/services/auth.service';
import { environment } from 'src/environments/environment';
import { UsersTable } from '@themes/metronic/app/_fake/fake-db/users.table';
import {
  AsideMenuConfig,
  DefaultMenuConfig,
} from './configs/aside-menu.config';
import { locale as enLang } from './configs/i18n/en';
import { locale as urduLang } from './configs/i18n/urdu';
import { User } from '@core/model/user/user';
import { UserOffcanvasComponent } from './topbar/user-offcanvas/user-offcanvas.component';
import { QuickPanelOffcanvasComponent } from './topbar/quick-panel-offcanvas/quick-panel-offcanvas.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipeModule } from '@shared/pipe/pipe.module';
import { BusinessService } from '../profile/services/business.service';
import { NotificationService } from '@core/services/notification.service';
import { GraphQLModule } from '@shared/modules/graphql/graphql.module';
import { AppUtilService } from '@shared/services/app-util.service';
import { version } from 'os';
import { env } from 'process';
import { Business } from '@core/model/business/business';
import { LocalStorageDataService } from '@core/services/local-storage.service';
import { Observable } from 'rxjs';
import { Country } from '@shared/model/country';
import { untilDestroyed } from '@ngneat/until-destroy';
@NgModule({
  declarations: [
    AsideDynamicComponent,
    AsideComponent,
    AsideStaticComponent,
    AsideSearchComponent,
    ScriptsInitComponent,
    HeaderMobileComponent,
    AsideComponent,
    AsideStaticComponent,
    AsideSearchComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    TopbarComponent,
    LanguageSelectorComponent,
    HeaderMenuDynamicComponent,
    UserOffcanvasComponent,
    QuickPanelOffcanvasComponent,
    Error1Component,
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslationModule,
    InlineSVGModule,
    ExtrasModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    CoreModule,
    SubheaderModule,
    DropdownMenusModule,
    MatProgressSpinnerModule,
    PipeModule,
    GraphQLModule,
  ],
  exports: [
    AsideDynamicComponent,
    TranslationModule,
    InlineSVGModule,
    ExtrasModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    CoreModule,
    SubheaderModule,
    HeaderMobileComponent,
    AsideComponent,
    AsideStaticComponent,
    AsideSearchComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    TopbarComponent,
    LanguageSelectorComponent,
    HeaderMenuDynamicComponent,
    UserOffcanvasComponent,
    QuickPanelOffcanvasComponent,
    ScriptsInitComponent,
    Error1Component,
  ],
  providers: [BusinessService, NotificationService, AppUtilService],
})
export class Layout2Module {
  user: User;
  business: Business;
  countries$: Observable<Country[]>;
  constructor(
    layoutService: LayoutService,
    menu: DynamicAsideMenuService,
    translationService: TranslationService,
    private authService: AuthService,
    
  ) {
    this.authService.user$.subscribe((x) => {
      this.user = x;
      var localVersion = localStorage.getItem("version");
      if(localVersion && localVersion !== environment.version){
        localStorage.clear();
      }
      localStorage.setItem("version", environment.version);
    });
    // layoutService.setConfig(LayoutConfig);
    menu.setMenu(AsideMenuConfig);
    this.authService.business$.subscribe((selectedBusiness) => {
      menu.setMenu(selectedBusiness?.id ? AsideMenuConfig : DefaultMenuConfig);
    });

    // register translations
    translationService.loadTranslations(enLang, urduLang);
  }
}



