import { Injectable } from '@angular/core';
import { BaseService } from '@shared/services/base.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Message, MessageParams } from '@shared/services/message-params';
import { Country, GetCountriesGQL } from '@generated/queries';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageDataService extends BaseService {
  systemMessagesUrl;
  public systemObjects$: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  private systemObjects: string[] = [];
  private unsubscribe$$ = new Subject<void>();
  constructor(private countriesQuery: GetCountriesGQL) {
    super();
    localStorage.setItem('systemObjects', JSON.stringify(this.systemObjects));
  }

  getMessage$(param: MessageParams): Observable<string> {
    let msg = '';
    let messages = Message;

    if (param.crud) {
      msg =
        param.type == 'error'
          ? messages.Crud.error
          : messages.Crud.success
              .replace('{_object_}', param.object)
              .replace('{_action_}', param.crud)
              .replace('pind', 'pinned')
              .replace('refundd', 'refunded')
              .replace('assignedd', 'assigned')
              .replace('enabledd', 'enabled')
              .replace('disabledd', 'disabled')
              .replace('loadd', 'loaded')
              .replace('syncd', 'synced')
              .replace(/^"(.*)"$/, '$1');
    }

    return of(msg);
  }

  async getCountries$(): Promise<Observable<any[]>> {
    const countries = await localStorage.getItem('countries');
    let jsonCountries: any[] = [];
  
    try {
      jsonCountries = JSON.parse(countries) || [];
    } catch (err) {
      jsonCountries = [];
    }
  
    if (countries && jsonCountries.length > 0) {
      // If data exists in local storage, return it as an observable
      return of(jsonCountries);
    } else {
      // Fetch from API if not in local storage
      return this.countriesQuery
        .fetch()
        .pipe(
          map((response) => response.data.countries),
          tap(async (countries: Country[]) => {
            await localStorage.setItem('countries', JSON.stringify(countries));
          })
        );
    }
  }
  
  async getCountryFlag(countryName: string) {
    let flag = '';
    (await this.getCountries$())
      .toPromise()
      .then((x) => {
        const country = x.find(
          (x) => x.name.toLowerCase() === countryName.toLowerCase()
        );
        if (country) {
          const code = country.alpha2Code;
          flag = `https://www.countryflags.io/${code}/shiny/64.png`;
        }
      });
    return flag;
  }
  getSystemObjects$(): BehaviorSubject<string[]> {
    return this.systemObjects$;
  }
  getFetchPolicy(systemObject: string) {
    let fetchPolicy: any = 'cache-first';
    if (
      this.systemObjects.some((x) =>
        x.toLowerCase().includes(systemObject.toLowerCase())
      )
    ) {
      fetchPolicy = 'network-only';
      this.systemObjects = this.systemObjects.filter(
        (x) => !x.toLowerCase().includes(systemObject.toLowerCase())
      );
    }
    this.systemObjects$.next(this.systemObjects);
    return fetchPolicy;
  }
  addToSystemObjects(systemObject: string) {
    this.systemObjects.push(systemObject);
    localStorage.setItem('systemObjects', JSON.stringify(this.systemObjects));
    this.systemObjects$.next(this.systemObjects);
  }
  ngOnDestroy() {
    this.unsubscribe$$.next();
  }
}
